<template>
  <div>
    <w-top-bar>Detalles del Cupón</w-top-bar>
    <div v-if="item" class="h-full p-5 bg-gray-200">
      <FeathersVuexFormWrapper :item="item" watch>
        <!-- <template v-slot="{ clone, save, reset, remove }"> -->
        <template v-slot="{ clone, save }">
          <div>
            <div class="flex w-full p-5 text-sm bg-white rounded-md shadow">
              <div class="w-2/5 md:pr-4">
                <div class="text-lg font-medium">Información General</div>
                <div class="text-gray-700">
                  La información se mostrara en la página de promoción del
                  Cupón.
                </div>
              </div>
              <div class="flex-grow">
                <w-input label="Alias" v-model="clone.name" />
                <w-input label="Código" v-model="clone.code" />
                <w-textarea
                  description="Texto para el banner que notifica al cliente del descuento."
                  label="Texto del Banner (HTML)"
                  v-model="clone.bannerText"
                />
                <w-html
                  description="Bloque HTML, que se muestra sobre el banner del descuento."
                  label="Contenido del bloque (HTML)"
                  v-model="clone.bannerHtml"
                />
                <w-html
                  description="Bloque HTML, que se muestra sobre cuando no hay mas cupones disponibles para usar."
                  label="No quedán mas cupones (HTML)"
                  v-model="clone.soldOutHtml"
                />
                <w-select
                  label="Tipo de Cupón"
                  v-model="clone.type"
                  :options="discountTypes"
                />
                <div class="mt-5">
                  <w-switch label="Activo" v-model="clone.isPublic"></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    El Cupón desactivado no podra ser utilizado por los
                    clientes.
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 md:mr-4">
                <div class="text-lg font-medium">Detalles del Cupón</div>
                <div class="text-gray-700">
                  Configura los detalles de descuento del Cupón.
                </div>
              </div>
              <div class="flex-grow">
                <div v-if="clone.type == 'amount'">
                  <w-money
                    v-model="clone.amount.ars"
                    label="Monto de Descuento en Pesos"
                    name="monto de descuento en pesos"
                    currency="ars"
                    placeholder="100 ARS"
                    class="mb-2"
                  />
                  <w-money
                    v-model="clone.amount.usd"
                    label="Monto de Descuento en Dolares"
                    name="monto de descuento en dolares"
                    currency="uds"
                    :dolar="150"
                    placeholder="100 USD"
                    class="mb-2"
                  />
                </div>
                <div v-if="clone.type == 'percentage'">
                  <w-input
                    v-model="clone.percentage"
                    label="Porcentaje de Descuento"
                    name="porcentaje de descuento"
                    currency="%"
                    placeholder="10%"
                    class="mb-2"
                  />
                </div>

                <div class="opacity-50 cursor-not-allowed">
                  <div class="mt-5">
                    <w-switch
                      label="Envío Gratuito"
                      v-model="clone.freeShipping"
                    ></w-switch>
                    <div class="text-sm text-gray-500 md:text-normal">
                      Selecciona esta opción si quieres bonificar el envío.
                    </div>
                  </div>
                </div>
                <w-input
                  v-model="clone.description"
                  label="Descripción Interna"
                  name="descripcion"
                  description="Para uso interno en pedidos y envíos"
                  currency="%"
                  placeholder="Descripción"
                  class="mt-2"
                />
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Condiciones</div>
                <div class="text-gray-700">Limitá el uso del cupon.</div>
              </div>
              <div class="w-3/5">
                <div class="flex flex-col py-2 space-y-2">
                  <w-money
                    v-model="clone.minTotal.ars"
                    label="Monto Mínimo en Pesos"
                    name="monto de mínimo en pesos"
                    currency="ars"
                    placeholder="100 ARS"
                    class="mb-2"
                  />
                  <w-money
                    v-model="clone.minTotal.usd"
                    label="Monto de Mínimo en Dolares"
                    name="monto de mínimo en dolares"
                    currency="uds"
                    :dolar="150"
                    placeholder="100 USD"
                    class="mb-2"
                  />
                  <div>
                    <div class="mt-5">
                      <w-switch
                        label="Limite de fecha (Inicio)"
                        v-model="clone.dateLimitStart"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Selecciona esta opción si el Cupón es válido desde una
                        fecha y hora.
                      </div>
                    </div>
                    <div v-if="clone.dateLimitStart" class="mt-5">
                      <w-date-time
                        label-day="Fecha de Inicio"
                        v-model="clone.dateStart"
                      />
                    </div>
                  </div>
                  <div class="mt-4 pt-4">
                    <w-switch
                      label="Limite de fecha (Vencimiento)"
                      v-model="clone.dateLimit"
                    ></w-switch>
                    <div class="text-sm text-gray-500 md:text-normal">
                      Selecciona esta opción si el Cupón es válido hasta una
                      fecha y hora.
                    </div>
                  </div>
                  <div v-if="clone.dateLimit" class="mt-5">
                    <w-date-time
                      label-day="Fecha de Vencimiento"
                      v-model="clone.date"
                    />
                  </div>
                  <div>
                    <div class="mt-5">
                      <w-switch
                        label="Permanente"
                        v-model="clone.permanente"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        El vencimiento se reinicia cada dia, mostrando como
                        limite las 12:00 hs del dia corriente.
                      </div>
                    </div>
                  </div>
                  <!-- <div class="">
                    <div class="mt-5">
                      <w-switch
                        label="Limite de categorias"
                        v-model="clone.typeLimit"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Selecciona esta opción si el Cupón solo es válido para
                        una cátegoria de productos.
                      </div>
                    </div>
                    <div v-if="clone.typeLimit" class="mt-5">
                      <multiselect
                        key="product-types"
                        v-model="clone.productTypesArray"
                        @input="clone.productType = $event.map((e) => e.key)"
                        :options="productTypes"
                        track-by="key"
                        label="label"
                        :searchable="false"
                        placeholder="Selecciona una categoria"
                        selected-label="Seleccionado"
                        select-label="Enter para seleccionar"
                        deselect-label="Enter para quitar"
                        :allow-empty="false"
                        :multiple="true"
                      ></multiselect>
                    </div>
                  </div> -->
                  <div class="">
                    <div class="mt-5">
                      <w-switch
                        label="Productos en Carrito"
                        v-model="clone.productLimit"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Selecciona esta opción si el Cupón solo es válido si se
                        encuentra alguno de los productos seleccionados en el
                        carrito de compras.
                      </div>
                    </div>
                    <div v-if="clone.productLimit" class="mt-5">
                      <multiselect
                        key="products-in-cart"
                        v-model="clone.products"
                        :options="products"
                        track-by="name"
                        label="name"
                        :searchable="false"
                        placeholder="Selecciona un libro"
                        selected-label="Seleccionado"
                        select-label="Enter para seleccionar"
                        deselect-label="Enter para quitar"
                        :allow-empty="false"
                        :multiple="true"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="">
                    <div class="mt-5">
                      <w-switch
                        label="Limite de productos"
                        v-model="clone.onlyProducts"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        El Cupón solo será válido para aplicarse en los
                        productos seleccionados.
                      </div>
                    </div>
                    <div v-if="clone.onlyProducts" class="mt-5">
                      <!-- <w-select
                        label="Categoria de Producto"
                        v-model="clone.productType"
                        :options="productTypes"
                      /> -->
                      <multiselect
                        key="allow-products"
                        v-model="clone.allowProducts"
                        :options="products"
                        track-by="name"
                        label="name"
                        :searchable="false"
                        placeholder="Selecciona un libro"
                        selected-label="Seleccionado"
                        select-label="Enter para seleccionar"
                        deselect-label="Enter para quitar"
                        :allow-empty="false"
                        :multiple="true"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="">
                    <div class="mt-5">
                      <w-switch
                        label="Limite de categorias"
                        v-model="clone.onlyCategories"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        El Cupón solo será válido para aplicarse en las
                        categorias seleccionadas.
                      </div>
                    </div>
                    <div v-if="clone.onlyCategories" class="mt-5">
                      <multiselect
                        key="allow-categories"
                        v-model="clone.allowCategories"
                        :options="categories"
                        track-by="key"
                        label="label"
                        :searchable="false"
                        placeholder="Selecciona una categoria"
                        selected-label="Seleccionada"
                        select-label="Enter para seleccionar"
                        deselect-label="Enter para quitar"
                        :allow-empty="false"
                        :multiple="true"
                      ></multiselect>
                    </div>
                  </div>
                  <div>
                    <div class="mt-5">
                      <w-switch
                        label="Ofrecer dedicatoria"
                        v-model="clone.tieneDedicatoriaAudio"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Selecciona esta opción si querés ofrecer la posibilidad
                        de agregar una dedicatoria por Whatsapp.
                      </div>
                    </div>
                    <div v-if="clone.tieneDedicatoriaAudio" class="mt-5">
                      <w-input
                        label="Limites de caracteres"
                        placeholder="100"
                        v-model="clone.dedicatoriaAudio"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="mt-5">
                      <w-switch
                        label="Limite de uso"
                        v-model="clone.useLimit"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Selecciona esta opción si el Cupón tiene limites de uso.
                      </div>
                    </div>
                    <div class="mt-5">
                      <w-switch
                        label="Activo global"
                        v-model="clone.globalActive"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Si esta activo, el cupon se aplica automaticamente, sin
                        que el usuario tenga que ingresar el codigo.
                      </div>
                    </div>
                    <div v-if="clone.useLimit" class="mt-5">
                      <w-input
                        label="¿Cuántas veces puede ser usado?"
                        placeholder="100"
                        v-model="clone.limits"
                      />
                    </div>
                  </div>
                  <div v-if="clone.useLimit">
                    <div class="mt-5">
                      <w-switch
                        label="Reiniciar el limite diariamente"
                        v-model="clone.restartLimit"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Reiniciar el limite todos los días
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="mt-5">
                      <w-switch
                        label="Limite de eventos"
                        v-model="clone.eventLimit"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Selecciona esta opción si el Cupón solo es válido para
                        eventos seleccionados.
                      </div>
                    </div>
                    <div>
                      <div v-if="clone.eventLimit" class="mt-5">
                        <multiselect
                          key="allow-eventos"
                          v-model="clone.allowEventos"
                          :options="eventos"
                          track-by="_id"
                          label="titulo"
                          :searchable="false"
                          placeholder="Selecciona un evento"
                          selected-label="Seleccionado"
                          select-label="Enter para seleccionar"
                          deselect-label="Enter para quitar"
                          :allow-empty="false"
                          :multiple="true"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-end py-2">
              <div
                v-if="saved"
                class="flex items-center text-sm font-medium text-green-600"
              >
                <div>Cambios guardados</div>
                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-green-600 transition duration-150 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div
                v-if="saving"
                class="flex items-center text-sm font-medium text-gray-600"
              >
                <div>Guardando cambios</div>

                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-gray-600 transition duration-150 ease-in-out animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>
              <button
                @click="doSave(save)"
                class="flex items-center px-4 py-2 text-sm font-bold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
              >
                Guardar
              </button>
            </div>
          </div>
        </template>
      </FeathersVuexFormWrapper>
    </div>
  </div>
</template>

<script>
import { FeathersVuexFormWrapper, makeFindMixin } from "feathers-vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "EditCoupons",
  components: {
    FeathersVuexFormWrapper,
    Multiselect,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    // Returns a new Todo if the route `id` is 'new', or returns an existing Todo.
    item() {
      const { Coupons } = this.$FeathersVuex.api;

      return this.id === "new" ? new Coupons() : Coupons.getFromStore(this.id);
    },
    productsParams() {
      return {
        query: {
          // type: { $in: ["books", "magazine"] },
          $sort: {
            name: 1,
          },
          $limit: 300,
          $select: ["name", "_id", "type"],
        },
      };
    },
    categoriesParams() {
      return {
        query: {
          $sort: {
            label: 1,
          },
          $limit: 300,
          $select: ["label", "_id", "key"],
        },
      };
    },
    eventosParams() {
      return {
        query: {
          $sort: {
            titulo: 1,
          },
          estado: "en-venta",
          $limit: 300,
          $select: ["titulo", "estado", "_id"],
        },
      };
    },
  },
  watch: {
    id: {
      handler(val) {
        // Early return if the route `:id` is 'new'
        if (val === "new") {
          return;
        }
        const { Coupons } = this.$FeathersVuex.api;
        const existingRecord = Coupons.getFromStore(val);

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Coupons.get(val);
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true,
    },
  },
  data() {
    return {
      saved: false,
      saving: false,
      discountTypes: [
        { key: "amount", label: "Monto Fijo" },
        { key: "percentage", label: "Porcentaje" },
        // { key: "entradas", label: "Entradas a eventos" },
      ],
      productTypes: [
        { key: "books", label: "Libro" },
        { key: "ebook", label: "Libro Digital" },
        { key: "magazine", label: "Revista" },
        { key: "streamings", label: "Evento Streaming" },
      ],

      deliveryTimes: [
        { key: 0, label: "En el día" },
        { key: "3-days", label: "1-3 Días" },
        { key: "1-week", label: "1 Semana" },
        { key: "2-week", label: "15 Días" },
        { key: "4-week", label: "15-30 Días" },
      ],
    };
  },
  mixins: [
    makeFindMixin({ service: "products" }),
    makeFindMixin({ service: "categories" }),
    makeFindMixin({ service: "eventos" }),
  ],
  methods: {
    async doSave(save) {
      this.saved = false;
      this.saving = true;

      let saved = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(() => {
        self.saved = false;
      }, 3000);
      this.handleSaveReponse(saved);
    },
    handleSaveReponse(savedCoupons) {
      // Redirect to the newly-saved item
      console.log("handleSaveReponse", savedCoupons);
      if (this.id === "new") {
        this.$router.push({ params: { id: savedCoupons._id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
